import "@aws-amplify/ui-react/styles.css";
import {
  defaultDarkModeOverride,
  ThemeProvider,
  Card,
  View,
} from "@aws-amplify/ui-react";

import { MainBody } from "./GadgetComponents";

import { useContext, useEffect } from "react";
import { PzPrimeContext } from "./Context";
import awsmobile from "./aws-exports";
import { Amplify, Hub, AuthModeStrategyType } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import AuthenticationPage from "./GadgetComponents/AuthenticationPage";
import SunEditorReact from "./GadgetComponents/HtmlEditor/SunEditorReact";

const updatedAwsConfig = {
  ...awsmobile,
  oauth: {
    ...awsmobile.oauth,
  },
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
  Storage: {
    AWSS3: {
      bucket: awsmobile.aws_user_files_s3_bucket,
    },
  },
};

Amplify.configure(updatedAwsConfig);

function App() {
  const { colorMode, setColorMode } = useContext(PzPrimeContext);

  const Css = `
  .outline{
    outline:1px solid #AEB3B7;
  }
  .adminOutline{
    border-bottom: 1px solid #c5c5c5;
  }
  ::-webkit-scrollbar {
    width: 15px;               /* width of the entire scrollbar */
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #b0b3b5;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid #c5c5c5;  /* creates padding around scroll thumb */
    
  }
  .loginTop{
    z-index:999;
  }
  [data-amplify-theme="my-theme"]{
    height:100vh
  }
  [data-amplify-authenticator] {
    margin: 0 15px
  }
`;

  const darkCss = `
  ::-webkit-scrollbar {
    width: 16px;               /* width of the entire scrollbar */
    background-color: #424242;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #424242;      /* color of the tracking area */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #686868;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid #424242;  /* creates padding around scroll thumb */
  }
`;
  useEffect(() => {
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    setColorMode(prefersDarkMode ? "dark" : "light");
  }, [setColorMode]);

  defaultDarkModeOverride["tokens"]["colors"]["background"]["primary"] =
    "#191f21";

  const theme = {
    name: "my-theme",
    overrides: [defaultDarkModeOverride],
  };

  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    Hub.listen("auth", (data) => {
      console.log(data);
      if (data?.payload?.event === "signIn") {
        // userDetails.emailId = data.payload.data.attributes.email;
        // setUserDetails(userDetails);
        navigate("/");
      }
      if (data?.payload?.event === "signOut") {
        window.location.reload();
      }
    });
  }, []);

  return (
    <>
      <style>{colorMode === "dark" ? Css + darkCss : Css}</style>
      <Routes>
        <Route
          path="/login"
          element={
            <View
              position={"absolute"}
              backgroundColor={"#fff"}
              width={"100%"}
              height={"100%"}
              className="loginTop"
            >
              <AuthenticationPage />
            </View>
          }
        />
        <Route path="/edit-proposal" element={<SunEditorReact />} />
      </Routes>
      {
        <ThemeProvider theme={theme} colorMode={colorMode}>
          <Card
            padding={"0"}
            direction={"column"}
            gap={"0px"}
            width={"100%"}
            minHeight={"100vh"}
            className="appScrollBar"
            display={
              pathname === "/login" || pathname === "/edit-proposal"
                ? "none"
                : "flex"
            }
          >
            <MainBody />
          </Card>
        </ThemeProvider>
      }
    </>
  );
}

export default App;
