import React from "react";
import {
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  Text,
  View,
} from "@aws-amplify/ui-react";
import { ModalComponent } from "../index";

const ContactInfoModal = ({ props, modalPosition }) => {
  const { projectName, isContactVisible, setIsContactVisible } = props;
  const recipient = "team@permitzip.com";
  const subject = `New InstantQuote Inquiry: ${projectName}`;
  // const body = "This is the body of the email.";

  const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    subject
  )}}`;

  return (
    <ModalComponent
      position={modalPosition}
      showModal={isContactVisible}
      onClose={() => setIsContactVisible(false)}
      children={
        <Card
          display={"flex"}
          direction={{
            base: "column",
            small: "column",
            medium: "row",
            large: "row",
            xl: "row",
          }}
          gap={{
            base: "10px",
            small: "15px",
            medium: "20px",
          }}
          padding={"0px"}
          overflow={"auto"}
          height={{
            base: "500px",
            small: "600px",
            medium: "auto",
          }}
        >
          <Flex
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            gap={{
              base: "5px",
              small: "15px",
              medium: "20px",
            }}
            textAlign={"center"}
            border={"1px solid #c5c5c5"}
            borderRadius={"20px"}
            padding={"10px"}
            minWidth={"230px"}
          >
            <View>
              <Icon
                fontSize={"84px"}
                borderRadius={"20px"}
                // className="iconHover"
                padding={"3px"}
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 7.3252C3 6.79476 3.21071 6.28605 3.58579 5.91098C3.96086 5.53591 4.46957 5.3252 5 5.3252H19C19.5304 5.3252 20.0391 5.53591 20.4142 5.91098C20.7893 6.28605 21 6.79476 21 7.3252M3 7.3252V17.3252C3 17.8556 3.21071 18.3643 3.58579 18.7394C3.96086 19.1145 4.46957 19.3252 5 19.3252H19C19.5304 19.3252 20.0391 19.1145 20.4142 18.7394C20.7893 18.3643 21 17.8556 21 17.3252V7.3252M3 7.3252L12 13.3252L21 7.3252"
                    stroke="#c5c5c5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Icon>
              <Heading marginTop={"10px"}>Send an Email</Heading>
            </View>
            <Text>
              Email the PermitZIP team for more information;{" "}
              <b>check your inbox first</b> for a sample proposal based on this
              estimate!
            </Text>
            <Button
              variation="link"
              fontSize={"15px"}
              onClick={() => {
                window.location.href = mailtoLink;
              }}
            >
              team@permitzip.com
            </Button>
          </Flex>
          <Flex
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            textAlign={"center"}
            gap={{
              base: "5px",
              small: "15px",
              medium: "15px",
            }}
            border={"1px solid #c5c5c5"}
            borderRadius={"20px"}
            padding={"10px"}
            minWidth={"230px"}
          >
            <View>
              <Icon
                fontSize={"84px"}
                borderRadius={"20px"}
                // className="iconHover"
                padding={"3px"}
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 4.3252H9L11 9.3252L8.5 10.8252C9.57096 12.9967 11.3285 14.7542 13.5 15.8252L15 13.3252L20 15.3252V19.3252C20 19.8556 19.7893 20.3643 19.4142 20.7394C19.0391 21.1145 18.5304 21.3252 18 21.3252C14.0993 21.0881 10.4202 19.4317 7.65683 16.6684C4.8935 13.905 3.23705 10.2259 3 6.3252C3 5.79476 3.21071 5.28605 3.58579 4.91098C3.96086 4.53591 4.46957 4.3252 5 4.3252Z"
                    stroke="#c5c5c5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Icon>
              <Heading marginTop={"10px"}>Talk to Sales</Heading>
            </View>
            <Text>
              Have more questions? Pick up the phone and chat with a member of
              our Sales and Consulting team!
            </Text>
            <Button variation="link" fontSize={"15px"}>
              <a
                href="tel:8338969335"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                +1 (833) 896 9335
              </a>
            </Button>
          </Flex>
        </Card>
      }
    />
  );
};

export default ContactInfoModal;
