import {
  Button,
  CheckboxField,
  Divider,
  Flex,
  Heading,
  Icon,
  SliderField,
  Text,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  View,
} from "@aws-amplify/ui-react";
import { useState, useEffect, useContext } from "react";
import { DataStore } from "aws-amplify";
import {
  DisciplineBasePrice,
  BasePrice,
  TradeSupervision,
  PriceClass,
} from "../models";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PzPrimeContext } from "../Context";
import ModalComponent from "./ModalComponent";

const AdjustPricingForm = () => {
  const navig = useNavigate();

  const {
    changeInAdjustPricing,
    setChangeInAdjustPricing,
    colorMode,
    userDetails,
    formatNumberUS,
  } = useContext(PzPrimeContext);

  const tradeConversion = {
    mech: TradeSupervision.MECH,
    elec: TradeSupervision.ELEC,
    plum: TradeSupervision.PLUMB,
  };

  const [basePrice, setBasePrice] = useState({
    disciplineBasePrice: {
      mech: {
        setUpFee: 0,
        costPerSF: 0,
      },
      elec: {
        setUpFee: 0,
        costPerSF: 0,
      },
      plum: {
        setUpFee: 0,
        costPerSF: 0,
      },
    },
    taxRate: 0,
    applyToSetupFees: false,
    globalPriceAdjustment: 1,
  });

  const [changeInValue, setChangeInValue] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const [onSave, setOnSave] = useState(false);

  const [openPricingHistory, setOpenPricingHistory] = useState(false);

  const [basePriceHistory, setBasePriceHistory] = useState([]);

  async function getData() {
    let tempBasePrice = basePrice;
    let value = await DataStore.query(BasePrice);
    console.log(value);
    if (value.length > 0) {
      value.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      let trades = ["mech", "elec", "plum"];
      for (let trade of trades) {
        tempBasePrice["disciplineBasePrice"][trade]["setUpFee"] = value[0][
          "globalMultiplierOnSetup"
        ]
          ? Math.round(
              (value[0]["disciplines"][trade]["setUpFee"] /
                value[0]["globalMultiplier"]) *
                100
            ) / 100
          : value[0]["disciplines"][trade]["setUpFee"];
        tempBasePrice["disciplineBasePrice"][trade]["costPerSF"] =
          Math.round(
            (value[0]["disciplines"][trade]["costPerSF"] /
              value[0]["globalMultiplier"]) *
              100
          ) / 100;
      }
      tempBasePrice["taxRate"] = value[0]["taxRate"] * 100;
      tempBasePrice["applyToSetupFees"] = value[0]["globalMultiplierOnSetup"];
      tempBasePrice["globalPriceAdjustment"] = value[0]["globalMultiplier"];
    }
    console.log(tempBasePrice);
    setBasePrice(JSON.parse(JSON.stringify(tempBasePrice)));
  }

  function loadData(priceRecord) {
    let tempBasePrice = basePrice;
    let trades = ["mech", "elec", "plum"];
    for (let trade of trades) {
      tempBasePrice["disciplineBasePrice"][trade]["setUpFee"] = priceRecord[
        "globalMultiplierOnSetup"
      ]
        ? Math.round(
            (priceRecord["disciplines"][trade]["setUpFee"] /
              priceRecord["globalMultiplier"]) *
              100
          ) / 100
        : priceRecord["disciplines"][trade]["setUpFee"];
      tempBasePrice["disciplineBasePrice"][trade]["costPerSF"] =
        Math.round(
          (priceRecord["disciplines"][trade]["costPerSF"] /
            priceRecord["globalMultiplier"]) *
            100
        ) / 100;
    }
    tempBasePrice["taxRate"] = priceRecord["taxRate"] * 100;
    tempBasePrice["applyToSetupFees"] = priceRecord["globalMultiplierOnSetup"];
    tempBasePrice["globalPriceAdjustment"] = priceRecord["globalMultiplier"];
    setBasePrice(JSON.parse(JSON.stringify(tempBasePrice)));
    setChangeInAdjustPricing(true);
    setOpenPricingHistory(false);
  }

  useEffect(() => {
    getData();
  }, [onSave]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = true;
      console.log("beforeunload");
    };
    if (changeInAdjustPricing) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [changeInAdjustPricing]);

  useEffect(() => {}, [changeInValue]);

  function changeBasePrice(e) {
    setChangeInAdjustPricing(true);
    let value;
    if (e.target.value < 0) value = 0;
    else value = parseFloat(e.target.value);
    if (e.target.name === "taxRate") basePrice[e.target.name] = value;
    else {
      let [discipline, field] = e.target.name.split("-");
      basePrice["disciplineBasePrice"][discipline][field] = value;
    }
    setBasePrice(basePrice);
    setChangeInValue(!changeInValue);
  }

  async function savePrice() {
    let isFieldEmpty = false;
    console.log(basePrice);
    if (!basePrice["globalPriceAdjustment"])
      basePrice["globalPriceAdjustment"] = 1;
    else
      basePrice["globalPriceAdjustment"] =
        Math.round(basePrice["globalPriceAdjustment"] * 100) / 100;
    setBasePrice(basePrice);

    let disciplineBasePrice = {};
    for (let trade in basePrice["disciplineBasePrice"]) {
      if (
        basePrice["disciplineBasePrice"][trade]["setUpFee"] &&
        basePrice["disciplineBasePrice"][trade]["costPerSF"]
      ) {
        let setUpFee = basePrice["applyToSetupFees"]
          ? basePrice["disciplineBasePrice"][trade]["setUpFee"] *
            basePrice["globalPriceAdjustment"]
          : basePrice["disciplineBasePrice"][trade]["setUpFee"];
        let costPerSF =
          basePrice["disciplineBasePrice"][trade]["costPerSF"] *
          basePrice["globalPriceAdjustment"];
        disciplineBasePrice[trade] = new DisciplineBasePrice({
          discipline: tradeConversion[trade],
          setUpFee: setUpFee,
          costPerSF: costPerSF,
        });
      } else {
        isFieldEmpty = true;
        break;
      }
    }

    if (isNaN(basePrice["taxRate"]) || !basePrice["taxRate"]) {
      isFieldEmpty = true;
    }

    if (isFieldEmpty) {
      setIsEmpty(true);
      Swal.fire({
        icon: "error",
        title: "Pricing Not Saved!",
        text: "Please make sure to fill in all fields before updating base pricing",
        showConfirmButton: true,
      });
    } else {
      await DataStore.save(
        new BasePrice({
          date: new Date().toISOString(),
          author: userDetails.emailId,
          disciplines: disciplineBasePrice,
          globalMultiplier: basePrice["globalPriceAdjustment"],
          globalMultiplierOnSetup: basePrice["applyToSetupFees"],
          taxRate: basePrice["taxRate"] / 100,
        })
      )
        .then((res) => {
          console.log(res);
          setChangeInAdjustPricing(false);
          setIsEmpty(false);
          setOnSave(!onSave);
          Swal.fire({
            icon: "success",
            title: "Values Saved",
            showConfirmButton: true,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: true,
          });
        });
    }
  }

  const customCss = `
  .effective-pricing-results{
    min-width: 110px;
  }
  .base-pricing-slider{
    min-width: 100px;
    max-width: 150px;
  }
  .custom-textfield-class .amplify-input {
    background-color:#eff0f0;
      color:#89949f;
      font-weight:500;
  }
  .slider-caption{
    text-wrap:nowrap;
  }
  .amplify-sliderfield__label {
      display: flex;
      justify-content: center;
      font-size:20px;
  }
  .amplify-sliderfield__thumb {
      border:none;
      background-color:#367588;
      border-radius:5px;
      height:22px;
      width:22px;
      cursor:pointer;
  }
  .amplify-sliderfield__thumb:hover {
      border:none;
      background-color:#367588;
  }
  .amplify-sliderfield__thumb:focus {
      box-shadow:none;
  }
  .outline{
    outline: 1px solid rgba(191, 191, 191, 0.80);
    z-index:3;
  }
  .invisibleBlockPL:after {
    content: "";
    display: block;
    height: 100px;
    width: 100%;
  }
  `;

  const darkCss = `
    .custom-textfield-class .amplify-input {
      background-color:#3a3d40;
      color:#89949f;
    }`;

  return (
    <>
      <style>{colorMode === "dark" ? customCss + darkCss : customCss}</style>

      <Flex width={"100%"} direction={"column"} gap={"0px"}>
        <Flex
          className="outline"
          padding="15px 70px 10px 34px"
          alignItems="center"
          boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
          width={"100%"}
          gap={"0"}
          position={"sticky"}
          top={"190px"}
          backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
        >
          <Button
            variation="link"
            textAlign={"center"}
            padding={"10px 25px 10px 5px"}
            margin={"0px 15px"}
            onClick={() => {
              if (changeInAdjustPricing) {
                if (
                  window.confirm(
                    "You have unsaved changes, do you want to exit?"
                  )
                ) {
                  setChangeInAdjustPricing(false);
                  navig("/");
                }
              } else {
                navig("/");
              }
            }}
          >
            <Icon
              ariaLabel="Close"
              fontSize={"25px"}
              pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
            />
          </Button>
          <Heading margin={"5px"} padding={"0"} level={4}>
            Pricing Library
          </Heading>
          <Button
            onClick={() => savePrice()}
            margin={"0px 20px"}
            color={"#fff"}
            fontWeight="600"
            backgroundColor={"brand.primary.80"}
          >
            Save Updates
          </Button>
          <Button
            onClick={async () => {
              let basePrices = await DataStore.query(BasePrice);
              basePrices.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
              });
              setBasePriceHistory(basePrices);
              setOpenPricingHistory(true);
            }}
            margin={"0px 20px"}
            color={"#fff"}
            fontWeight="600"
            backgroundColor={"brand.primary.80"}
          >
            Revision History
          </Button>
        </Flex>
        <Flex padding={"20px 25px"}>
          <Flex
            width={"fit-content"}
            direction={{
              xs: "column",
              small: "column",
              medium: "column",
              large: "column",
              xl: "row",
              xxl: "row",
            }}
          >
            <Flex width={"fit-content"} direction={"column"}>
              <Flex
                direction={{
                  xs: "column",
                  small: "column",
                  medium: "row",
                  large: "row",
                  xl: "row",
                  xxl: "row",
                }}
                padding={"20px"}
                width={"fit-content"}
                justifyContent={"flex-start"}
                alignItems={{
                  xs: "center",
                  small: "center",
                  medium: "flex-start",
                  large: "flex-start",
                  xl: "flex-start",
                  xxl: "flex-start",
                }}
                gap={"20px"}
                borderRadius={"30px"}
                // border={"1px solid #c5c5c5"}
              >
                {/* Base pricing card*/}
                <Flex
                  direction={"column"}
                  width={"fit-content"}
                  maxWidth={"500px"}
                  border={"1px solid #c5c5c5"}
                  borderRadius={"15px"}
                  padding={"15px"}
                  height={"100%"}
                  justifyContent={"center"}
                >
                  <Flex direction={"column"}>
                    <Text
                      fontSize={"150%"}
                      fontWeight={"500"}
                      marginBottom={"10px"}
                      width={"fit-content"}
                    >
                      Base Pricing
                    </Text>
                    <Flex direction={"column"}>
                      <Text fontSize={"120%"} fontWeight={"400"}>
                        Mechanical
                      </Text>
                      <Flex
                        // padding={"2px 20px"}
                        marginLeft={"20px"}
                        direction={"row"}
                        gap={"20px"}
                        width={"fit-content"}
                      >
                        <TextField
                          className="base-pricing-slider"
                          label="Set-Up Fee"
                          onFocus={(e) => e.target.select()}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="mech-setUpFee"
                          value={
                            basePrice["disciplineBasePrice"]["mech"]["setUpFee"]
                            // ? basePrice["disciplineBasePrice"]["mech"]["setUpFee"]
                            // : ""
                          }
                          onChange={(e) => changeBasePrice(e)}
                          hasError={
                            isEmpty &&
                            !basePrice["disciplineBasePrice"]["mech"][
                              "setUpFee"
                            ]
                              ? true
                              : false
                          }
                        />
                        <TextField
                          className="base-pricing-slider"
                          label="Cost Per SF"
                          onFocus={(e) => e.target.select()}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="mech-costPerSF"
                          value={
                            basePrice["disciplineBasePrice"]["mech"][
                              "costPerSF"
                            ]
                            // ? basePrice["disciplineBasePrice"]["mech"]["costPerSF"]
                            // : ""
                          }
                          onChange={(e) => changeBasePrice(e)}
                          hasError={
                            isEmpty &&
                            !basePrice["disciplineBasePrice"]["mech"][
                              "costPerSF"
                            ]
                              ? true
                              : false
                          }
                        />
                      </Flex>
                    </Flex>
                    <Flex direction={"column"}>
                      <Text fontSize={"120%"} fontWeight={"400"}>
                        Electrical
                      </Text>
                      <Flex
                        marginLeft={"20px"}
                        direction={"row"}
                        gap={"20px"}
                        width={"fit-content"}
                      >
                        <TextField
                          className="base-pricing-slider"
                          label="Set-Up Fee"
                          onFocus={(e) => e.target.select()}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="elec-setUpFee"
                          value={
                            basePrice["disciplineBasePrice"]["elec"]["setUpFee"]
                            // ? basePrice["disciplineBasePrice"]["elec"]["setUpFee"]
                            // : ""
                          }
                          onChange={(e) => changeBasePrice(e)}
                          hasError={
                            isEmpty &&
                            !basePrice["disciplineBasePrice"]["elec"][
                              "setUpFee"
                            ]
                              ? true
                              : false
                          }
                        />
                        <TextField
                          className="base-pricing-slider"
                          label="Cost Per SF"
                          onFocus={(e) => e.target.select()}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="elec-costPerSF"
                          value={
                            basePrice["disciplineBasePrice"]["elec"][
                              "costPerSF"
                            ]
                            // ? basePrice["disciplineBasePrice"]["elec"]["costPerSF"]
                            // : ""
                          }
                          onChange={(e) => changeBasePrice(e)}
                          hasError={
                            isEmpty &&
                            !basePrice["disciplineBasePrice"]["elec"][
                              "costPerSF"
                            ]
                              ? true
                              : false
                          }
                        />
                      </Flex>
                    </Flex>
                    <Flex direction={"column"}>
                      <Text fontSize={"120%"} fontWeight={"400"}>
                        Plumbing
                      </Text>
                      <Flex
                        marginLeft={"20px"}
                        // padding={"2px 20px"}
                        direction={"row"}
                        gap={"20px"}
                        width={"fit-content"}
                      >
                        <TextField
                          className="base-pricing-slider"
                          label="Set-Up Fee"
                          onFocus={(e) => e.target.select()}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="plum-setUpFee"
                          value={
                            basePrice["disciplineBasePrice"]["plum"]["setUpFee"]
                            // ? basePrice["disciplineBasePrice"]["plum"]["setUpFee"]
                            // : ""
                          }
                          onChange={(e) => changeBasePrice(e)}
                          hasError={
                            isEmpty &&
                            !basePrice["disciplineBasePrice"]["plum"][
                              "setUpFee"
                            ]
                              ? true
                              : false
                          }
                        />
                        <TextField
                          className="base-pricing-slider"
                          label="Cost Per SF"
                          onFocus={(e) => e.target.select()}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="plum-costPerSF"
                          value={
                            basePrice["disciplineBasePrice"]["plum"][
                              "costPerSF"
                            ]
                            // ? basePrice["disciplineBasePrice"]["plum"]["costPerSF"]
                            // : ""
                          }
                          onChange={(e) => changeBasePrice(e)}
                          hasError={
                            isEmpty &&
                            !basePrice["disciplineBasePrice"]["plum"][
                              "costPerSF"
                            ]
                              ? true
                              : false
                          }
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                {/* Global Adjustment Slider*/}
                <Flex
                  direction={"column"}
                  padding={"15px"}
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"5px"}
                  // border={"1px solid #c5c5c5"}
                >
                  <Text
                    fontSize={"150%"}
                    fontWeight={"400"}
                    textAlign={"center"}
                  >
                    Global Adjustment
                  </Text>
                  <Text
                    fontSize={"100%"}
                    textAlign={"center"}
                    fontStyle={"italic"}
                    marginTop={"15%"}
                    className="slider-caption"
                  >
                    Turn up the Volume!
                  </Text>
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <Text
                      minWidth={"30px"}
                      fontSize={"250%"}
                      fontWeight={"bolder"}
                    >
                      ×
                    </Text>
                    <SliderField
                      className="custom-slider"
                      justifyContent={"center"}
                      height={"100%"}
                      orientation={"vertical"}
                      minWidth={"50px"}
                      // size="large"
                      min={20}
                      max={200}
                      value={basePrice["globalPriceAdjustment"] * 100}
                      formatValue={(val) =>
                        `${Math.round(
                          basePrice["globalPriceAdjustment"] * 100
                        )}%`
                      }
                      defaultValue={basePrice["globalPriceAdjustment"] * 100}
                      onChange={(val) => {
                        basePrice["globalPriceAdjustment"] =
                          parseInt(val) / 100;
                        setBasePrice(basePrice);
                        setChangeInValue(!changeInValue);
                        setChangeInAdjustPricing(true);
                      }}
                    />
                    <Text
                      minWidth={"30px"}
                      color={"#fff"}
                      fontSize={"280%"}
                      fontWeight={"bolder"}
                      marginBottom={"6px"}
                    >
                      =
                    </Text>
                  </Flex>
                  <Text
                    fontSize={"100%"}
                    textAlign={"center"}
                    fontStyle={"italic"}
                    marginBottom={"15%"}
                    className="slider-caption"
                  >
                    Turn down the Heat!
                  </Text>
                </Flex>

                {/* Effective pricing card*/}
                <Flex
                  direction={"column"}
                  width={"fit-content"}
                  maxWidth={"500px"}
                  border={"1px solid #c5c5c5"}
                  borderRadius={"15px"}
                  padding={"15px"}
                  height={"100%"}
                >
                  <Text
                    fontSize={"150%"}
                    fontWeight={"500"}
                    width={"fit-content"}
                  >
                    Effective Pricing
                  </Text>
                  <CheckboxField
                    margin={"10px 0"}
                    label="Apply Adjustment to Set-Up Fees"
                    checked={basePrice["applyToSetupFees"]}
                    onChange={(e) => {
                      basePrice["applyToSetupFees"] = e.target.checked;
                      setBasePrice(basePrice);
                      setChangeInValue(!changeInValue);
                    }}
                  />
                  {Object.keys(basePrice["disciplineBasePrice"]).map(
                    (trade) => {
                      return (
                        <Flex direction={"column"}>
                          <Text fontSize={"130%"} fontWeight={"400"}>
                            {trade === "mech"
                              ? "Mechanical"
                              : trade === "elec"
                              ? "Electrical"
                              : "Plumbing"}
                          </Text>
                          <Flex
                            padding={"2px 20px"}
                            direction={"row"}
                            gap={"20px"}
                          >
                            <Flex direction={"column"} gap={"5px"}>
                              <Text>Set-Up Fee:</Text>
                              <Text
                                fontSize={"125%"}
                                fontWeight={"500"}
                                marginLeft={"20px"}
                                className="effective-pricing-results"
                              >
                                ${" "}
                                {basePrice["applyToSetupFees"]
                                  ? formatNumberUS(
                                      basePrice["disciplineBasePrice"][trade][
                                        "setUpFee"
                                      ] * basePrice["globalPriceAdjustment"]
                                    )
                                  : formatNumberUS(
                                      basePrice["disciplineBasePrice"][trade][
                                        "setUpFee"
                                      ]
                                    )}
                              </Text>
                            </Flex>
                            <Flex direction={"column"} gap={"5px"}>
                              <Text>Cost Per SF:</Text>
                              <Text
                                fontSize={"125%"}
                                fontWeight={"500"}
                                marginLeft={"20px"}
                                className="effective-pricing-results"
                              >
                                {formatNumberUS(
                                  basePrice["disciplineBasePrice"][trade][
                                    "costPerSF"
                                  ] * basePrice["globalPriceAdjustment"]
                                )}{" "}
                                $/SF
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      );
                    }
                  )}
                </Flex>
              </Flex>
              <Divider />
            </Flex>

            <Flex direction={"column"} paddingLeft={"10px"}>
              <Text fontSize={"150%"} fontWeight={"400"}>
                Tax Rate
              </Text>
              <Flex padding={"2px 5px"} direction={"row"} gap={"20px"}>
                <TextField
                  fontSize={"18px"}
                  // width={"60%"}
                  label="Tax Rate"
                  name="taxRate"
                  labelHidden={true}
                  onFocus={(e) => e.target.select()}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={
                    basePrice["taxRate"]
                    // ? basePrice["taxRate"]
                    // : ""
                  }
                  onChange={(e) => changeBasePrice(e)}
                  hasError={isEmpty && !basePrice["taxRate"] ? true : false}
                  outerEndComponent={
                    <Text
                      borderRadius={"0 4px 4px 0"}
                      border={"1px solid #89949f"}
                      color={"#89949f"}
                      height={"100%"}
                      paddingTop={"5px"}
                      padding={"3px 10px 0 10px"}
                      style={{ "border-left": "none" }}
                    >
                      %
                    </Text>
                  }
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <ModalComponent
        showModal={openPricingHistory}
        onClose={() => setOpenPricingHistory(false)}
        width="auto"
        maxHeight="70%"
        maxWidth="auto"
        height="auto"
        marginTop="90px"
        children={
          <>
            <Flex
              direction={"column"}
              width={"100%"}
              padding={"10px 15px"}
              borderRadius={"10px"}
              // border={"1px solid #c5c5c5"}
              overflow={"auto"}
              minWidth={"500px"}
              minHeight={"300px"}
              gap={"15px"}
            >
              <Text
                fontSize={"130%"}
                fontWeight={"500"}
                textAlign={"center"}
                marginBottom={"5px"}
                backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
                position={"sticky"}
                top={"-20px"}
              >
                Pricing History
              </Text>
              <Table caption="" highlightOnHover={false} variation="bordered">
                <TableHead>
                  <TableRow>
                    <TableCell as="th">No.</TableCell>
                    <TableCell as="th">Date</TableCell>
                    <TableCell as="th">Author</TableCell>
                    <TableCell as="th">Adjustment Level</TableCell>
                    <TableCell as="th">Load Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {basePriceHistory.map((priceRecord, index) => {
                    return (
                      <TableRow>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>
                          {new Date(priceRecord.date).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{priceRecord.author}</TableCell>
                        <TableCell>
                          <Text>
                            {formatNumberUS(
                              priceRecord.globalMultiplier * 100,
                              0 //decimals
                            )}
                            %
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Button
                            width={"110px"}
                            onClick={() => {
                              Swal.fire({
                                icon: "warning",
                                title: "Load Pricing?",
                                text: "This will not update current settings, but unsaved changes will be lost.",
                                showCancelButton: true,
                                confirmButtonText: "Load Data",
                                reverseButtons: true,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  loadData(priceRecord);
                                }
                              });
                            }}
                          >
                            Load
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Flex>
          </>
        }
      />
    </>
  );
};

export default AdjustPricingForm;
