// React
import { createContext, useState } from "react";
import packagePriceTotal from "./InstantQuoteCalculator";
import ProjectCalculator from "./ProjectCalculator";
import { DataStore, Storage } from "aws-amplify";
import { SpaceTemplate, PromoCode, Proposal, Project } from "../models";
import isEmail from "validator/lib/isEmail";

// Context
const PzPrimeContext = createContext();

// Context Provider
const InstantQuoteProvider = (props) => {
  const [colorMode, setColorMode] = useState("dark");

  const [currentStage, setCurrentStage] = useState("homePage");

  const [projectName, setProjectName] = useState("");

  const [authorizerName, setAuthorizerName] = useState("");

  const [authorizerEmail, setAuthorizerEmail] = useState("");

  const [selectedOrg, setSelectedOrg] = useState();

  const [isOrgSelected, setIsOrgSelected] = useState(false);

  const [address, setAddress] = useState("");

  const [spaceFlags, setSpaceFlags] = useState([]);

  const [selectedMarkets, setSelectedMarkets] = useState([]);

  const [filterMarkets, setFilterMarkets] = useState([]);

  const [changeInLocation, setChangeInLocation] = useState(false);

  const [buildingArea, setBuildingArea] = useState(0);

  const [projectArea, setProjectArea] = useState(0);

  const [isRequirementMet, setIsRequirementMet] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [numberOfBuildings, setNumberOfBuildings] = useState(1);

  const [activeStage, setActiveStage] = useState(0);

  const [projectId, setProjectId] = useState();

  const [spaceId, setSpaceId] = useState();

  const [changeInSpace, setChangeInSpace] = useState(false);

  const [changeInInput, setChangeInInput] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState("Collaborator");

  const [projectDescription, setProjectDescription] = useState("");

  const [constructionType, setConstructionType] = useState("New Construction");

  const [projectPriceSheets, setProjectPriceSheets] = useState();

  const [instantQuoteId, setInstantQuoteId] = useState("");

  const [user, setUser] = useState("");

  const [gotToSignUp, setGotToSignUp] = useState(true);

  const [configuredSpaces, setConfiguredSpaces] = useState();

  const [projectSpaces, setProjectSpaces] = useState({});

  const [changeInAdjustPricing, setChangeInAdjustPricing] = useState(false);

  const [lastSentPropoosal, setLastSentPropoosal] = useState();

  const [buildingSquareFootage, setBuildingSquareFootage] = useState({
    brewery: 0,
    restaurant: 0,
    office: 0,
    warehouse: 0,
    multiFamily: 0,
    medical: 0,
    uniqueLayouts: 1,
  });

  const [discipline, setDiscipline] = useState({
    mechanical: 0,
    electrical: 0,
    plumbing: 0,
    // gas: 0,
  });

  const [userDetails, setUserDetails] = useState({
    name: "",
    phoneNumber: "",
    emailId: "",
  });

  const [organizationDetails, setOrganizationDetails] = useState({
    name: "",
    billingEmail: "",
    address: "",
    tagLine: "",
  });

  const [distanceMatrixData, setDistanceMatrixData] = useState({
    distance: 0,
    time: 0,
  });

  const [location, setLocation] = useState({
    lat: 37.538372,
    lng: -77.434011,
  });

  const stageValue = {
    projectDetails: 1,
    marketSelector: 2,
    spaceSelector: 3,
    // finalCheck: 4,
    // packageSelector: 5,
  };

  const [spaceImages, setSpaceImages] = useState({});

  function properNaming(field) {
    if (field) {
      const putSpace = field.split("_").join(" ");
      let name = "";
      for (const word of putSpace.split(" ")) {
        name +=
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
      }
      return name;
    } else return "";
  }

  const calculatePackagePrice = () => {
    return packagePriceTotal(
      discipline,
      buildingSquareFootage,
      constructionType
    );
  };

  //To validate the email
  const validateEmail = (emailAddress) => {
    return isEmail(emailAddress);
  };

  function fileNameSanitizer(fileName) {
    let sanitizedFileName = "";
    for (let letter of fileName) {
      if (letter == " ") sanitizedFileName += "_";
      else if (/^[a-zA-Z0-9!\-_'!()]$/.test(letter))
        sanitizedFileName += letter;
    }
    return sanitizedFileName;
  }

  function addCommaToPrice(price, decimals = 2) {
    // Deprecated function, do not implemnent. See formatNumberUS().
    return Number(price).toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }

  function formatNumberUS(number, decimals = 2) {
    // Adds comma's to numbers in US number system, and round to decimals.
    return Number(number).toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }

  // Funciton to show the dynamic contract on a new window or tab
  function showPDF(PDF) {
    console.log("PDF --> ", PDF);
    var fileURL = URL.createObjectURL(PDF);
    window.open(fileURL);
  }

  function decodePDF(encodedPDF) {
    var byteCharacters = atob(encodedPDF);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    console.log(file);
    return file;
  }

  function checkMinimumRequirements() {
    let errorMsg = "";
    let minimumRequirementsFlag = 1;
    if (projectName.length === 0) {
      errorMsg += "Project Name not entered \n";
      minimumRequirementsFlag = 0;
    }
    if (address.length === 0) {
      errorMsg += "Address not entered \n";
      minimumRequirementsFlag = 0;
    }
    if (distanceMatrixData.distance === 0) {
      errorMsg += "This Address location is too far \n";
      minimumRequirementsFlag = 0;
    }
    let tradeFlag = 0;
    for (const trade in discipline) {
      if (discipline[trade] === 1) {
        tradeFlag = 1;
        break;
      }
    }
    if (tradeFlag === 0) {
      errorMsg += "Minimum one Trade needed \n";
      minimumRequirementsFlag = 0;
    }
    if (projectArea === 0) {
      errorMsg += "Project Area not entered \n";
      minimumRequirementsFlag = 0;
    }
    if (numberOfBuildings < 1) {
      errorMsg += "Number Of Building should be greater than 0 \n";
      minimumRequirementsFlag = 0;
    }
    if (minimumRequirementsFlag === 0) return true;
    else {
      return false;
    }
  }
  function checkRequirements() {
    if (selectedMarkets && selectedMarkets.length > 0) return false;
    else return true;
  }

  function requirementChecker(navPage) {
    if (navPage === "/market-selector") {
      if (!checkMinimumRequirements())
        return [checkMinimumRequirements(), navPage];
      else return [checkMinimumRequirements(), "/project-details"];
    } else if (navPage === "/space-selector") {
      if (!checkMinimumRequirements()) {
        if (!checkRequirements()) return [checkRequirements(), navPage];
        else return [checkRequirements(), "/market-selector"];
      } else return [checkMinimumRequirements(), "/project-details"];
    } else if (navPage === "/final-checks") {
      if (!checkMinimumRequirements()) {
        if (!checkRequirements()) {
          if (isRequirementMet) return [!isRequirementMet, navPage];
          else return [!isRequirementMet, "/space-selector"];
        } else return [checkRequirements(), "/market-selector"];
      } else return [checkMinimumRequirements(), "/project-details"];
    }
  }

  function checkStage(toStage) {
    return stageValue[currentStage] >= stageValue[toStage];
  }

  async function getProposalData(templateProject) {
    const spaceTemplates = await DataStore.query(SpaceTemplate);

    const tradeConversion = {
      MECH: "mech",
      ELEC: "elec",
      PLUMB: "plum",
    };

    // Define proposal data structure
    let proposalData = {
      isInstantQuote: {
        description:
          "True if project was submitted through Instant Quote form, false if custom-built",
        dataType: "boolean",
      },
      userName: {
        description:
          "Optional input - may be first and last, first only, or missing. Use email address as primary point of contact.",
        dataType: "text",
      },
      userEmail: {
        description: "User email",
        dataType: "text",
      },
      address: {
        description: "Project street address",
        dataType: "text",
      },
      projectName: {
        description: "Name of the project",
        dataType: "text",
      },
      projectDescription: {
        hideReference: true,
        description: "Name of the project",
        dataType: "text",
        value:templateProject?.projectDescription
      },
      totalPrice: {
        description: "Total price including taxes",
        dataType: "number",
      },
      mechCost: {
        description: "Total Cost for mechanical trade",
        dataType: "number",
      },
      elecCost: {
        description: "Total Cost for electrical trade",
        dataType: "number",
      },
      plumCost: {
        description: "Total Cost for plumbing trade",
        dataType: "number",
      },
      projectSubtotal: {
        description: "Subtotal (before promo codes)",
        dataType: "number",
      },
      promoCode: {
        description: "Promo code name",
        dataType: "text",
      },
      promoDiscount: {
        description: "Dollars discounted from initial subtotal",
        dataType: "number",
      },
      tax: {
        description: "Calculated tax",
        dataType: "number",
      },
      selectedTrades: {
        calculated: true,
        description:
          "Shows an inline, comma-separated list of selected trades on the project.",
        dataType: "text",
      },
      simpleSpaces: {
        calculated: true,
        dataType: "text",
        description:
          "Recommended to use on Instant Quote proposals instead of [projectSpaces] - shows SF per space, but does not show smaller area breakouts. Area breakdowns are automatically weighted and invisible to IQ users. \nChange [projectSpaces] metadata to edit.",
      },
      instantQuoteAssumptions: {
        calculated: true,
        dataType: "text",
        description:
          "Details the IQ area breakout assumptions. Includes data for all spaces currently on IQ, but will only render spaces included in project.",
        spaceLibrary: {},
      },
      projectSpaces: {
        calculated: true,
        description:
          "Shows more detail than simpleSpaces - includes sub-lists under each space for square footage per area",
        dataType: "text",
        spaces: {
          dataType: "valueList",
          default: {
            spaceType: "Space Title 1",
            areas: {
              dataType: "valueList",
              default: {
                name: "Area 1",
                SF: 1000,
              },
            },
          },
        },
      },
      ccAddresses: {
        hideReference: true,
        description: "",
        dataType: "text",
      },
      projectID: {
        hideReference: true,
        description: "",
        dataType: "text",
        value: templateProject.id,
      },
      Date: {
        hideReference: true,
        description: "",
        dataType: "text",
      },
    };

    const date = new Date();
    const d = date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
      .split(" ");

    proposalData.Date["value"] = `${d[1]} ${d[0]}, ${d[2]}`;

    // Get data from project
    if (templateProject.isInstantQuote)
      proposalData["isInstantQuote"]["value"] = templateProject.isInstantQuote;

    if (templateProject.userName)
      proposalData["userName"]["value"] = templateProject.userName;

    if (templateProject.userEmail)
      proposalData["userEmail"]["value"] = templateProject.userEmail;

    if (templateProject.location.address)
      proposalData["address"]["value"] = templateProject.location.address;

    if (templateProject.name)
      proposalData["projectName"]["value"] = templateProject.name;

    if (templateProject.priceSheet.total)
      proposalData["totalPrice"]["value"] = parseFloat(
        templateProject.priceSheet.total
      );

    if (templateProject.priceSheet.initialSubtotal)
      proposalData["projectSubtotal"]["value"] = parseFloat(
        templateProject.priceSheet.initialSubtotal
      );

    if (templateProject.priceSheet.taxes)
      proposalData["tax"]["value"] = parseFloat(
        templateProject.priceSheet.taxes
      );

    for (let trade of Object.keys(tradeConversion)) {
      if (templateProject.tradeSupervisions.includes(trade))
        proposalData[tradeConversion[trade] + "Cost"]["value"] = parseFloat(
          templateProject.priceSheet[tradeConversion[trade] + "Total"]
        );
    }

    if (
      templateProject.promoCodes &&
      templateProject.promoCodes.length > 0 &&
      templateProject.promoCodes[0].code
    )
      proposalData["promoCode"]["value"] = templateProject.promoCodes[0].code;

    if (
      templateProject.priceSheet.promoDiscounts &&
      templateProject.priceSheet.promoDiscounts.length > 0 &&
      templateProject.priceSheet.promoDiscounts[0].discount
    )
      proposalData["promoDiscount"]["value"] =
        templateProject.priceSheet.promoDiscounts[0].discount;

    // For project spaces
    let spaceIndex = 0;
    for (const space in templateProject.spaceDict) {
      proposalData["projectSpaces"]["spaces"][spaceIndex] = {
        spaceType: space,
        areas: {
          dataType: "valueList",
        },
        path: `projectSpaces-spaces-${spaceIndex}`,
      };
      let areaIndex = 0;
      for (const area of templateProject.spaceDict[space].areas) {
        proposalData["projectSpaces"]["spaces"][spaceIndex]["areas"][
          areaIndex
        ] = {
          name: area.areaTitle,
          SF: Math.round(area.area),
          path: `projectSpaces-spaces-${spaceIndex}-areas-${areaIndex}`,
        };
        areaIndex++;
      }
      spaceIndex++;
    }

    // For IQ-Assumptions
    let iqSpaceIndex = 0;
    for (const space of spaceTemplates) {
      if (space.spaceTemplateInstantQuoteSpaceId) {
        proposalData.instantQuoteAssumptions.spaceLibrary[iqSpaceIndex] = {
          spaceType: space.templateName,
          areaAssumptions: {},
        };
        let iqAreaIndex = 0;
        for (const areaObj of space.areas) {
          proposalData.instantQuoteAssumptions.spaceLibrary[
            iqSpaceIndex
          ].areaAssumptions[iqAreaIndex] = {
            name: areaObj.areaTitle,
            weight: areaObj.instantQuoteWeight,
          };
          iqAreaIndex++;
        }
        iqSpaceIndex++;
      }
    }

    // Update calculated values
    return updateProposalData(proposalData);
  }

  // Calculate the .value field for calculate equal to true objects
  function updateProposalData(proposalData) {
    let enteredSpaces = [];

    // For selected trades
    let selectedTrades = [];
    let tradeConversion = {
      mechCost: "Mechanical (HVAC)",
      elecCost: "Electrical (Power & Lighting)",
      plumCost: "Plumbing (Water and Sanitary)",
    };
    for (const trade in tradeConversion) {
      if (
        proposalData[trade].hasOwnProperty("value") &&
        proposalData[trade].value > 0
      )
        selectedTrades.push(tradeConversion[trade]);
    }
    if (selectedTrades.length === 0) proposalData.selectedTrades["value"] = "";
    else if (selectedTrades.length === 1)
      proposalData.selectedTrades["value"] = selectedTrades[0];
    else if (selectedTrades.length === 2) {
      proposalData.selectedTrades["value"] =
        selectedTrades[0] + " and " + selectedTrades[1];
    } else if (selectedTrades.length > 2) {
      proposalData.selectedTrades["value"] = selectedTrades
        .slice(0, selectedTrades.length - 1)
        .join(", ");
      proposalData.selectedTrades["value"] +=
        ", and " + selectedTrades[selectedTrades.length - 1];
    }

    // For simple spaces
    let simpleSpaces = "<ul>";
    for (const space of Object.keys(proposalData.projectSpaces.spaces)) {
      if (!isNaN(space)) {
        proposalData.projectSpaces.spaces[space].totalSF = 0;
        enteredSpaces.push(proposalData.projectSpaces.spaces[space].spaceType);
        simpleSpaces +=
          "<li>" + proposalData.projectSpaces.spaces[space].spaceType + ": ";
        let totalSF = 0;
        for (const area of Object.keys(
          proposalData.projectSpaces.spaces[space].areas
        )) {
          if (!isNaN(area)) {
            totalSF += proposalData.projectSpaces.spaces[space].areas[area].SF;
          }
        }
        proposalData.projectSpaces.spaces[space].totalSF = totalSF;
        simpleSpaces += formatNumberUS(totalSF, 0) + " SF</li>";
      }
    }
    if (simpleSpaces !== "<ul>") simpleSpaces += "</ul>";
    else simpleSpaces = "";
    proposalData.simpleSpaces["value"] = simpleSpaces;

    // For project spaces
    let spaces = "<ul>";
    for (const space of Object.keys(proposalData.projectSpaces.spaces)) {
      if (!isNaN(space)) {
        spaces +=
          "<li>" +
          proposalData.projectSpaces.spaces[space].spaceType +
          `: ${formatNumberUS(
            proposalData.projectSpaces.spaces[space].totalSF,
            0
          )} SF<ul>`;
        for (const area of Object.keys(
          proposalData.projectSpaces.spaces[space].areas
        )) {
          if (!isNaN(area)) {
            spaces +=
              "<li>" +
              proposalData.projectSpaces.spaces[space].areas[area].name +
              ": " +
              formatNumberUS(
                proposalData.projectSpaces.spaces[space].areas[area].SF,
                0
              ) +
              " SF</li>";
          }
        }
        spaces += "</ul></li>";
      }
    }
    if (spaces !== "<ul>") spaces += "</ul>";
    else spaces = "";
    proposalData.projectSpaces["value"] = spaces;

    // For IQ-Assumptions
    let iqAssumptions = "<ul>";
    for (const space of Object.values(
      proposalData.instantQuoteAssumptions.spaceLibrary
    )) {
      if (enteredSpaces.includes(space.spaceType)) {
        iqAssumptions += "<li>" + space.spaceType + ":<ul>";
        for (const area of Object.values(space.areaAssumptions)) {
          iqAssumptions += "<li>" + area.name + ": " + area.weight + "%</li>";
        }
        iqAssumptions += "</ul></li>";
      }
    }
    if (iqAssumptions !== "<ul>") iqAssumptions += "</ul>";
    else iqAssumptions = "";
    proposalData.instantQuoteAssumptions["value"] = iqAssumptions;

    console.log("proposalData --> ", proposalData);

    return proposalData;
  }

  async function savePdfToS3(PDF, project_name) {
    let s3FileName = fileNameSanitizer(project_name);
    const date = new Date().toISOString();
    try {
      await Storage.put(`proposals/sent/${date}_${s3FileName}.pdf`, PDF, {
        contentType: "application/pdf",
      });
      return {
        msg: "success",
        key: `proposals/sent/${date}_${s3FileName}.pdf`,
      };
    } catch {
      return { msg: "error" };
    }
  }

  async function incrementPromoCodeUses(code) {
    const promoCodes = await DataStore.query(PromoCode, (promoCode) =>
      promoCode.code.eq(code)
    );
    if (promoCodes.length > 0) {
      let tempObj = JSON.parse(JSON.stringify(promoCodes[0]));
      if (tempObj.uses) tempObj.uses = parseInt(tempObj.uses) + 1;
      else tempObj.uses = 1;
      await DataStore.save(
        PromoCode.copyOf(promoCodes[0], (promoCodeCopy) => {
          promoCodeCopy.uses = tempObj.uses;
        })
      )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }

  async function saveProposal(project, proposalData, pdfKey) {
    let result = false;
    let proposal = "";
    await DataStore.save(
      new Proposal({
        projectId: project ? project.id : null,
        proposalData: proposalData,
        pdfKey: pdfKey,
      })
    )
      .then((res) => {
        console.log(res);
        result = true;
        proposal = res;
      })
      .catch((err) => console.log(err));
    return [result, proposal];
  }

  async function updateProjectRecord(project, proposal, proposalData) {
    if (project) {
      let currentProject = await DataStore.query(Project, project.id);
      let today = new Date().toISOString();
      let tempObj = JSON.parse(JSON.stringify(currentProject));
      if (!tempObj.proposals) tempObj.proposals = {};
      tempObj.proposals[today] = {
        proposalId: proposal.id,
        proposalData: proposalData,
      };
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.proposals = tempObj.proposals;
        })
      )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
  }

  const contextData = {
    isLoggedIn,
    setIsLoggedIn,
    projectSpaces,
    setProjectSpaces,
    isAdmin,
    ProjectCalculator,
    setIsAdmin,
    setSpaceImages,
    instantQuoteId,
    setInstantQuoteId,
    projectPriceSheets,
    colorMode,
    setColorMode,
    projectName,
    setProjectName,
    address,
    setAddress,
    distanceMatrixData,
    setDistanceMatrixData,
    location,
    setLocation,
    changeInLocation,
    setChangeInLocation,
    discipline,
    setDiscipline,
    addCommaToPrice,
    formatNumberUS,
    validateEmail,
    selectedOrg,
    setSelectedOrg,
    isOrgSelected,
    setIsOrgSelected,
    selectedMarkets,
    setSelectedMarkets,
    currentStage,
    setCurrentStage,
    selectedPackage,
    setSelectedPackage,
    buildingArea,
    setBuildingArea,
    projectArea,
    setProjectArea,
    numberOfBuildings,
    setNumberOfBuildings,
    constructionType,
    setConstructionType,
    changeInSpace,
    setChangeInSpace,
    activeStage,
    setActiveStage,
    checkStage,
    stageValue,
    spaceFlags,
    setSpaceFlags,
    spaceImages,
    changeInInput,
    setChangeInInput,
    projectId,
    setProjectId,
    spaceId,
    setSpaceId,
    authorizerName,
    setAuthorizerName,
    authorizerEmail,
    setAuthorizerEmail,
    filterMarkets,
    setFilterMarkets,
    organizationDetails,
    setOrganizationDetails,
    buildingSquareFootage,
    setBuildingSquareFootage,
    userDetails,
    setUserDetails,
    calculatePackagePrice,
    projectDescription,
    setProjectDescription,
    isRequirementMet,
    setIsRequirementMet,
    requirementChecker,
    user,
    setUser,
    configuredSpaces,
    setConfiguredSpaces,
    properNaming,
    setProjectPriceSheets,
    gotToSignUp,
    setGotToSignUp,
    getProposalData,
    updateProposalData,
    changeInAdjustPricing,
    setChangeInAdjustPricing,
    savePdfToS3,
    incrementPromoCodeUses,
    saveProposal,
    updateProjectRecord,
    showPDF,
    decodePDF,
    lastSentPropoosal,
    setLastSentPropoosal,
  };

  return (
    <PzPrimeContext.Provider value={contextData}>
      {props.children}
    </PzPrimeContext.Provider>
  );
};

export { PzPrimeContext, InstantQuoteProvider };
